<template>
  <div class="container-fluidpy-5">
    <div class="row">
      <div class="container py-5">
        <div class="col-12 col-md-6 offset-md-3">
          <form @submit.prevent @submit="printVoucher"
                autocomplete="off"
                class="col-12 voucherWrap pb-3 bg-white rounded-lg overflow-hidden shadow-lg">
            <div class="w-100 p-2 border-bottom mb-3">
              <b>{{$t("downloadVoucher")}}</b>
            </div>
            <b-form-group :label="$t('voucherCode')">
              <b-form-input v-model="form.code" />
            </b-form-group>
            <b-form-group :label="$t('receiversName')">
              <b-form-input v-model="form.name" />
            </b-form-group>
            <b-alert :show="error" variant="danger">{{message}}</b-alert>
            <div class="col-12 py-2 text-center">
              <b-button type="submit" variant="primary" :disabled="saving">
                <b-spinner v-if="saving" small class="float-left mt-1 mr-2"></b-spinner>
                {{$t('download')}}
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadVoucher",
  methods: {
    printVoucher() {
      this.saving = true;
      this.error = false;
      this.$http.post("/content/checkVoucher", this.form).then(response => {
        if(response.data.error===undefined) {
          this.saving = false;
          this.error = true
          this.message = this.$t('errors.unableToLoad')
        } else if(response.data.error) {
          this.saving = false;
          this.error = true
          this.message = response.data.message
        } else {
          this.form = {
            code: "",
            name: ""
          };
          this.startDownload(response.data.voucher)
        }
      }).catch((error)=>{
        this.saving = false;
        this.error = true
        this.message = error.toString()
      });
    },
    startDownload(voucher) {
      this.$http.get("/content/printVoucher", {
        responseType: 'arraybuffer',
        params : {
          voucherId : voucher
        }
      }).then(response => {
        this.saving = false
        let blob = new Blob([response.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob)
        window.open(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      }).catch((error)=>{
        this.saving = false
        this.error = true
        this.message = error.toString();
      })
    }
  },
  data() {
    return {
      saving: false,
      error: false,
      message: "",
      form: {
        code: "",
        name: ""
      }
    }
  }
}
</script>

<style scoped>

</style>
